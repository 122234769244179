import {userService} from '@/helpers/services/user.service';
import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password, rememberMe }) {
        commit('loginRequest', { email });

        userService.login(email, password, rememberMe)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    resetPassword({ dispatch }, email) {
        userService.resetPassword(email)
            .then(
                response => {
                    dispatch('notification/success', response.message, { root: true });
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    resetPasswordConfirm({ dispatch }, { token, password }) {
        userService.resetPasswordConfirm(token, password)
            .then(
                () => {
                    dispatch('notification/success', 'Password reset successful, redirecting to login page', { root: true });

                    setTimeout(() => {
                        router.push('/login');
                    }, 2000);
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    changePassword({ dispatch }, { oldPassword, newPassword }) {
        userService.changePassword(oldPassword, newPassword)
            .then(
                response => {
                    dispatch('notification/success', response.message, { root: true });
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    updateAccount({ dispatch, commit }, { name, avatar }) {
        userService.updateAccount(name, avatar)
            .then(
                response => {
                    dispatch('notification/success', response.message, { root: true });
                    commit('accountUpdated', response.user);
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    accountUpdated(state, user) {
        state.user = user;
        const localUser = JSON.parse(localStorage.getItem('user'));
        user.token = localUser.token;
        localStorage.setItem('user', JSON.stringify(user));
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

