export const baseUrl = 'https://api.gattacai.app';

export async function handleResponse(response) {
    // eslint-disable-next-line no-constant-condition
    if(response.status === 440) {
        localStorage.removeItem('user');

        setTimeout(() => {
            window.location.reload(true);
        }, 1000);

        return Promise.reject('Session expired, please login again. Redirecting to login page...');
    }

    const data = await response.json();

    if (!data.success) {
        const error = data && data.message;
        return Promise.reject(error);
    }

    return data;
}

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function sortPlayers(players) {
    const rankS = []
    const rankA = []
    const rankB = []
    const rankC = []
    const rankD = []

    try {
        const temp = players.slice();
        // sort the temp by value
        temp.sort((a, b) => b.value - a.value);

        rankS.push(temp[0]);
        temp.shift();

        let topScore = temp[0].value;
        while(0 < temp.length) {
            if (temp[0].value === topScore) {
                rankS.push(temp[0]);
                temp.shift();
            } else {
                topScore = temp[0].value;
                break;
            }
        }

        let j = 0;
        while(j < 4) {
            rankA.push(temp[0]);
            temp.shift();
            topScore = temp[0].value;
            j++;
        }

        // eslint-disable-next-line no-constant-condition
        while(true) {
            if (temp[0].value === topScore) {
                rankA.push(temp[0]);
                temp.shift();
            } else {
                topScore = temp[0].value;
                break;
            }
        }

        let i = 0;
        while(i < 9 && temp[0].value + 10 >= topScore) {
            rankB.push(temp[0]);
            temp.shift();
            i++;
        }
        topScore = temp[0].value;

        // eslint-disable-next-line no-constant-condition
        while(true) {
            if (temp[0].value === topScore) {
                rankB.push(temp[0]);
                temp.shift();
            } else {
                topScore = temp[0].value;
                break;
            }
        }

        i = 0;
        while(i < 9 && temp[0].value + 5 >= topScore) {
            rankC.push(temp[0]);
            temp.shift();
            i++;
        }
        topScore = temp[0].value;

        // eslint-disable-next-line no-constant-condition
        while(true) {
            if (temp[0].value === topScore) {
                rankC.push(temp[0]);
                temp.shift();
            } else {
                topScore = temp[0].value;
                break;
            }
        }

        i = 0;
        while(i < 9 && temp[0].value + 5 >= topScore) {
            rankD.push(temp[0]);
            temp.shift();
            i++;
        }
        topScore = temp[0].value;

        while(0 < temp.length) {
            if (temp[0].value === topScore) {
                rankD.push(temp[0]);
                temp.shift();
            } else {
                topScore = temp[0].value;
                break;
            }
        }
    } catch (error) { /* empty */ }

    return [rankS, rankA, rankB, rankC, rankD];
}
