import { createWebHistory, createRouter } from "vue-router";

import routes from './routes'
import store from "@/state/store";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// check if logged
router.beforeEach((routeTo, routeFrom, next) => {
  // check if authRequired is set on the route meta
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const loggeduser = localStorage.getItem('user');

  if (authRequired && !loggeduser) {
    return next('/login');
  }

  next();
})

// check if administrator
router.beforeEach((routeTo, routeFrom, next) => {
  const adminRequired = routeTo.matched.some((route) => route.meta.adminRequired);
  const loggeduser = localStorage.getItem('user');

    if (adminRequired && loggeduser) {
        const user = JSON.parse(loggeduser);
        if (user.role !== 1) {
            return next('/');
        }
    }

    next();
});

// execute beforeResolve (if any)
router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }

  next()
})

// redirect to 404 if route not found
router.beforeResolve((routeTo, routeFrom, next) => {
    if (!routeTo.matched.length) {
        next({ name: '404', params: { pathMatch: routeFrom.path.split('/').filter(Boolean) } })
    } else {
        next()
    }
});

// clear notification messages when changing route
router.beforeEach(() => {
  store.dispatch('notification/clear')
});

export default router
