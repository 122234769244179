import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    redirect: "/gm-tierlist",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/auth/login"),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.auth.status.loggeduser) {
          next({ name: "default" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgot-pwd",
    name: "Forgot password",
    component: () => import("./views/auth/recoverpw"),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.auth.status.loggeduser) {
          next({ name: "default" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/forgot-pwd/:token",
    name: "Forgot password token",
    component: () => import("./views/auth/recoverpwtoken"),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.auth.status.loggeduser) {
          next({ name: "default" });
        } else {
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/auth/logout.vue"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logout");
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  {
    path: "/gm-tierlist",
    name: "Tier List",
    meta: { authRequired: true },
    component: () => import("./views/pages/tierlist")
  },
  {
    path: "/player-comparison",
    name: "Player Comparison",
    meta: { authRequired: true },
    component: () => import("./views/pages/playercomparison")
  },
  {
    path: "/player/:region/:slug",
    name: "Player Page",
    meta: { authRequired: true },
    component: () => import("./views/pages/playerpage")
  },
  {
    path: "/super-stats",
    name: "Super Stats",
    meta: { authRequired: true },
    component: () => import("./views/pages/superstats")
  },
  {
    path: "/admin/users",
    name: "Manage Users",
    meta: {
      authRequired: true,
      adminRequired: true
    },
    component: () => import("./views/pages/admin/users.vue"),
  },
  {
    path: "/admin/seasons-events",
    name: "Seasons & Events",
    meta: {
      authRequired: true,
      adminRequired: true
    },
    component: () => import("./views/pages/admin/seasons-events.vue"),
  },
  {
    path: "/admin/teams",
    name: "Manage Teams",
    meta: {
      authRequired: true,
      adminRequired: true
    },
    component: () => import("./views/pages/admin/teams.vue"),
  },
  {
    path: "/admin/leagues",
    name: "Manage Leagues",
    meta: {
      authRequired: true,
      adminRequired: true
    },
    component: () => import("./views/pages/admin/leagues.vue"),
  },
  {
    path: "/my-account",
    name: "My Account",
    meta: { authRequired: true },
    component: () => import("./views/pages/my-account.vue"),
  },
  {
    path: "/admin/import-data",
    name: "Import Data",
    meta: {
      authRequired: true,
      adminRequired: true
    },
    component: () => import("./views/pages/admin/import-data.vue"),
  },
  {
    path: "/admin/players",
    name: "Manage Players",
    meta: {
      authRequired: true,
      adminRequired: true
    },
    component: () => import("./views/pages/admin/players.vue"),
  },
];
