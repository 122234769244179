import { baseUrl, handleResponse, authHeader } from './base';

export const userService = {
    login,
    logout,
    resetPassword,
    resetPasswordConfirm,
    changePassword,
    updateAccount,
    adminCreateUser,
    adminGetUsers,
    adminEditUser,
    adminDeleteUser
};

async function adminDeleteUser(user_id) {
    const response = await fetch(baseUrl + '/admin/user/' + user_id, {
        method: 'DELETE',
        headers: {
            ...authHeader()
        },
    });

    return handleResponse(response);
}

async function adminEditUser(user_id, avatar, name, mail, password, role, restrictPerms, permissions) {
    const formData = new FormData();

    if(avatar) { formData.append('avatar', avatar) }
    if(password) { formData.append('password', password) }
    formData.append('name', name);
    formData.append('mail', mail);
    formData.append('role', role);
    formData.append('permissions', permissions);
    formData.append('restrictPerms', restrictPerms);

    // content-type header will be automatically set
    const response = await fetch(baseUrl + '/admin/user/' + user_id, {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData
    });

    return handleResponse(response);
}

async function adminGetUsers() {
    const response = await fetch(baseUrl + '/admin/users', {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    });

    return handleResponse(response);
}

async function adminCreateUser(avatar, name, mail, password, role, restrictPerms, permissions) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('avatar', avatar);
    formData.append('mail', mail);
    formData.append('password', password);
    formData.append('role', role);
    formData.append('permissions', permissions);
    formData.append('restrictPerms', restrictPerms);

    // content-type header will be automatically set
    const response = await fetch(baseUrl + '/admin/user', {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData
    });

    return handleResponse(response);
}

async function login(mail, password, rememberMe) {
    const response = await fetch(baseUrl + '/session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mail, password, rememberMe })
    });

    const session = await handleResponse(response);
    if (session.token) {
        const response = await fetch(baseUrl + '/user', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + session.token,
            },
        });

        const data = await handleResponse(response);
        if (data.user) {
            const user = data.user;
            user.token = session.token;

            localStorage.setItem('user', JSON.stringify(user));

            return user;
        }
    }
}

async function resetPassword(mail) {
    const response = await fetch(baseUrl + '/user/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mail })
    });

    return handleResponse(response);
}

async function resetPasswordConfirm(token, password) {
    const response = await fetch(baseUrl + '/user/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password })
    });

    return handleResponse(response);
}

async function changePassword(oldPassword, newPassword) {
    const response = await fetch(baseUrl + '/user/change-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({ oldPassword, newPassword })
    });

    return handleResponse(response);
}

async function updateAccount(name, avatar) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('avatar', avatar);

    // content-type header will be automatically set
    const response = await fetch(baseUrl + '/user', {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData
    });

    return handleResponse(response);
}

function logout() {
    localStorage.removeItem('user');
}
