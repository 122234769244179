<template>
 <router-view></router-view>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>

import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

export default {
  name: 'App',
  components: {
    ConfirmDialog,
    Toast
  }
}
</script>
