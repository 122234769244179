import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  modules,
  strict: true
})

export default store

